
	#forgebox-modernize {

		section {
			margin-top: 0px;
			margin-bottom: 0px;
		}

		.container {
			margin-right: 0px !important;
			margin-left: 0px !important;
		}

		.main-content {
			margin-right: 0px !important;
			margin-left: 0px !important;
			margin: auto !important;
			padding-top: 10px;
			padding-bottom: 10px;
			max-width: 1340px;
		}

    .current-plan {
		color: rgb(227, 160, 8) !important;
		background-color: rgb(23, 43, 77) !important;
    }

    .current-plan-border {
		border: 4px solid rgb(227, 160, 8) !important;
    }

	#editions {
		width: 100%;
		height: auto;

	p {
		line-height: 1.75 !important;
		margin-left: 10px !important;
	}

	.col-md-3, .col-md-12 {
		padding-right: 10px;
		padding-left: 10px;
	}

	.card-community {
		border: 4px solid #172b4d;
		border-radius: 8px;
		margin-right: 5px;
		margin-left: 5px;
		margin-top: 15px;
		margin-bottom: 15px;
		background-color: #fff;
	}

	.card {
		margin-right: 5px;
		margin-left: 5px;
		border: 4px solid #172b4d;
		border-radius: 8px;
		background-color: #fff;
		height: 725px;
		position: relative;
	}

		.card-content {
			width: 100%;
			display: flex;
		}

		.card-description {
			width: 35%;
			text-align: center;
		}

		.card-detail {
			width: 65%;
			position: relative;
			padding-top: 2%;
			text-align: left;
		}

		.card-list {
			text-align: left !important;
			column-count: 3;
		}

		.btn-join {
			position: absolute;
			bottom: 0;
			left: 37%;
		}

		.forever {
			font-size: 25px;
		}

		.description {
			padding: 10px;
			line-height: 1.5;
			font-size: 15px;
			min-height: 115px;
			margin-bottom: 0px;
		}

		.fas {
			color: #8fc73e;
		}

		.card-title {
			background-color: #172b4d;
			text-align: center;
			color: #fff;
			font-weight: bold;
			padding-top: 10px;
			padding-bottom: 10px;
			margin-bottom: 0px !important;
		}

		.card-body {
			padding: 5px;
			text-align: center;
		}

		.btn-secondary {
			background-color: #838383;
			color: #fff;
			border: none;
		}

		.price {
			color: #003f53;
			font-weight: bold;
			font-size: 45px;
			margin-bottom: 0px;
			line-height: 1.3 !important;
		}

		.interval {
			display: block;
		}

		.btn-margin {
			margin-top: 10%;
			margin-bottom: 15%;
		}

		.card-footer {
			color: rgb(54, 155, 194);
			border-top: 1px solid silver;
			text-align: center;
			font-weight: 500;
			padding: 2%;
			position: absolute;
			bottom: 0;
			width: 100%;
		}

		.features {
			text-align: left;
			margin-top: 10px;
		}

		h3 {
			font-size: 45px;
			color: #003f53;
			margin-bottom: 0;
		}

		span {
			color: white;
			font-weight: bold;
		}

		img {
			height: 120px;
		}

		label {
			color: #757575;
			margin-top: 0px;
			font-weight: 0;
		}

			// *** MEDIA QUERY ***
			@media only screen and (max-width: 768px) {
        .col-md-3, .col-md-12 {
          padding-right: 10px !important;
          padding-left: 10px !important;
        }

				.btn {
          padding: 0.5rem 1rem !important;
          font-size: 12px !important;
        }

				.card-description, .card-detail {
					width: 100%;
				}

        .description {
          min-height: 139px !important;
          padding: 3px !important;
        }

        img {
          height: 100px !important;
        }

				.card-content {
					display: flow-root;
					padding: 10px;
				}

				.card-list {
					column-count: 1;
					text-align: left;
				}

				.btn-join {
					position: inherit;
					left: 0;
				}

				.card {
					height: 100% !important;
					margin-bottom: 10px;
				}

				.card-body {
					margin-bottom: 15px;
				}
			}

      // *** MEDIA QUERY ***
			@media only screen and (max-width: 600px) {
        .description {
          min-height: 80px !important;
          padding: 3px !important;
        }
      }
		}
	}
